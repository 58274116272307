import React from 'react';
import { Link } from 'react-router-dom'

export default function HomePage() {
    return (
        <div>
            <p>Congratulations on finding this site. I haven't distributed this link anywhere in a long time. You must be
             looking at a very old resume' from some database. To get an updated copy of my resume', please see <a href='https://www.linkedin.com/in/joseph--wilson/'>my linkedin profile</a>. 
            </p>
            <p>
                As you can tell, there's nothing interesting here on josephwilsondevskills.com. If you're really curous about why
                there isn't anything interesting on josephwilsondevskills.com, please see the brief explanation <Link to='/about'>here</Link>.
            </p>
            <p>Hey, I just added this to the homepage!</p>
        </div>
    )
}