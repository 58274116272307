import React from 'react';
import { Link } from 'react-router-dom';

export default function Menu() {
    return (
        <div class='flex-container flex-justify-content-end'>
            <h4>
                <Link to='/'>Home</Link>
                | 
                <Link to='/about'>About</Link>
            </h4>
        </div>
    )
}