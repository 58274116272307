import React from 'react';

export default function AboutPage() {
    return (
        <div>
            <h2>Why there's nothing interesting on josephwilsondevskills.com</h2>
            <p>
                I registered this domain for free several years ago.
            </p>
            <p>
                I was working for a hosting company, 
                they gave all of their empoloyees one free domain and one free hosting account. 
            </p>
            <p>
                At the time, I was only an aspiring software developer so I thought I would eventually use this domain
                to host a portfolio site that I could link in my resume' and job applications to help me get my first software job.
            </p>
            <p>
                I was able to get my first software job without building a portfolio site so
                I never bothered with building one. 
            </p>
            <p>
                I do use the domain for an email account though, so I figured I should at least put up
                a couple pages for it in case people see my email address and go looking. 
            </p>
            <p>
                If you're one of those people, sorry to dissappoint you.
            </p>
            <p>
                Please feel free to send me an email or a message on linkedin if you have any questions.
            </p>
            <p>
                Also, yes I know the site is very ugly. I built it in about half an hour and I don't expect
                anyone to actually find it. I'll probably spend more time setting up the DNS records and
                hosting than I spent on building the site. Cut me some slack.
            </p>
        </div>
    )
}