import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './App.css'
import Header from './common/header/Header';
import HomePage from './homepage/Homepage';
import AboutPage from './aboutpage/AboutPage';
import Menu from './common/menu/Menu';

function App() {
  return (
    <div class='flex-container-column margin-auto'>
      <BrowserRouter>
        <header>
          <Header />
          <Menu />
        </header>

        <main>
          <Switch>
            <Route path='/about'>
              <AboutPage />
            </Route>
            <Route path='/'>
              <HomePage/>
            </Route>
          </Switch>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
